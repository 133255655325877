import { API_V2 } from "@common/api";
import type { Transaction, TransactionShortView } from "@common/store";

export type GetAllTransactionQuery = {
  budget?: string;
  account?: string;
  is_approved?: boolean;
  future_spending?: boolean;
  incoming?: string;
  outgoing?: string;
  content?: string;
  contractor?: string;
  comment?: string;
  subcategory_id?: string;
  ordering?: string;
  page?: number | string;
};

export type GetAllTransactionPageLessQuery = Omit<GetAllTransactionQuery, "page">

export type GetAllTransactionResponse = {
  count: number;
  next?: string;
  previous?: string;
  results: Transaction[];
};

export type GetApprovedCountParams = {
  budget: string
  account: string
};

export type TransactionTransportType = {
  getAllWithPagination: (query: GetAllTransactionQuery) => Promise<GetAllTransactionResponse>
  getAllPageLess: (query: GetAllTransactionPageLessQuery) => Promise<Transaction[]>
  getApprovedCount: (query: GetApprovedCountParams) => Promise<{ approve_count: number }>
  create: (dto: TransactionShortView) => Promise<Transaction>;
  update: (id: string, dto: TransactionShortView) => Promise<Transaction>;
  delete: (id: string) => Promise<void>;
  deleteMany: (transactions: Array<{id: string, isTemplate: boolean}>) => Promise<void>;
  restoreMany: (transactions: { id: string, isTemplate: boolean }[]) => Promise<void>;
};

export const TransactionTransport: TransactionTransportType = {
  getAllWithPagination: async (query: GetAllTransactionQuery): Promise<GetAllTransactionResponse> => {
    const { data } = await API_V2.get<GetAllTransactionResponse>("/transactions", { params: query });
    return data;
  },
  create: async (dto: TransactionShortView): Promise<Transaction> => {
    const { data } = await API_V2.post<Transaction>("/transactions", dto);
    return data;
  },
  getAllPageLess: async (query): Promise<Transaction[]> => {
    const { data } = await API_V2.get<Transaction[]>("/transactions", { params: query });
    return data;
  },
  getApprovedCount: async (query): Promise<{ approve_count: number }> => {
    const { data } = await API_V2.get<{ approve_count: number }>("/transactions/approve_count", { params: query });
    return data;
  },
  update: async (id: string, dto: TransactionShortView): Promise<Transaction> => {
    const { data } = await API_V2.put<Transaction>(`/transactions/${id}`, dto);
    return data;
  },
  delete: async (id: string): Promise<any> => {
    const { data } = await API_V2.delete(`/transactions/${id}`);
    return data;
  },
  deleteMany: async (transactions: Array<{id: string, isTemplate: boolean}>): Promise<any> => {
    const { data } = await API_V2.post(`/transactions/remove_many`, { items: transactions });
    return data;
  },
  restoreMany: async (transactions: { id: string, isTemplate: boolean }[]): Promise<any> => {
    const { data } = await API_V2.post(`/transactions/restore_many`, { items: transactions });
    return data;
  },
};
