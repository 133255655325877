import styled from "styled-components";
import { ReactElement } from "react";

type Props = {
  label?: string
  Icon?: ReactElement
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

export function LabelWithIcon(props: Props): ReactElement {
  const { Icon, label, onClick } = props
  return (
    <Wrapper $active={Icon !== undefined} onClick={onClick}>
      {label !== undefined ? <Text>{label}</Text> : null}
      {Icon !== undefined ? <IconWrapper>{Icon}</IconWrapper> : null}
    </Wrapper>
  )
}

const IconWrapper = styled.div`
    display: flex;
    align-content: center;

    svg > path {
        fill: #13BDCE;
    }
`

const Wrapper = styled.div<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
  
  ${p => p.$active && `
    &:hover {
      svg > path {
        fill: #13BDCE;
      }
    }
  `}
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  font-family: Manrope, Inter, sans-serif;
  color: ${p => p.theme.colors.lightText};

  @media (max-width: 1180px) {
    font-size: 12px;
    line-height: 15px;
  }
`;
