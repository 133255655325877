import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  colors: {
    transparent: 'transparent',
    white: '#FFFFFF',
    black: '#000000',
    main: '#13BDCE',
    negative: '#ce1313',
    red: '#FF3A3A',
    green: '#009D69',
    dark: '#10A3B1',
    light: '#C7F5FA',
    contentMain: '#333333',
    contentSecondary: '#989898',
    backgroundSecondary: '#E1E1E1',
    backgroundMain: '#F7F7F7',
    backgroundError: '#F7D9D9',
    backgroundSuccess: '#D9F7ED',
    backgroundGold: '#F7F2D9',
    letterBackground: '#d9dcf7',
    letterMain: '#1323CE',
    foundationPositive: '#08CF70',
    gold: '#CEAF13',
    lightGold: '#FFF0D9',
    lightGrey: 'rgba(0, 0, 0, 0.12)',
    purple: '#61698A',
    lightnessGrey: '#E5E6E9',
    darkText: '#272C41',
    grey: '#8B9EA5',
    lightText: '#9497A9',
    placeholder: '#AAAAAA',
    lightBlue: '#C0D8EA',
  },
  order: {
    nav: 2,
    modal: 10,
  },
  text: {
    fontExtraSmall: '12px',
    fontSmall: '16px',
    fontMedium: '22px',
    fontLarge: '34px',
    fontExtraLarge: '48px',
    lineHeight: '1.3',
  },
  fontFamily: {
    main: 'Montserrat, sans-serif',
  },
};
