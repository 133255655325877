import styled from "styled-components";
import { ReactElement } from "react";

type Props = {
  Icon: ReactElement;
  mainText: string;
  secondaryText: string;
  onClick: () => void;
  primaryBtn?: boolean;
  classNames?: string;
  disabled?: boolean;
};

export function ComplexButtonComponent(props: Props): ReactElement {
  const { Icon, secondaryText, mainText, onClick, primaryBtn, classNames, disabled } = props;
  return (
    <ComplexButton primaryBtn={primaryBtn} onClick={onClick} disabled={disabled}>
      <IconWrapper className={classNames}>{Icon}</IconWrapper>
      <LabelWrapper>
        <LabelMainText>{mainText}</LabelMainText>
        <LabelSecondaryText>{secondaryText}</LabelSecondaryText>
      </LabelWrapper>
    </ComplexButton>
  );
}

const ComplexButton = styled.button<{ primaryBtn?: boolean, disabled?: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 8px;
  background-color: var(--color-white-100);
  border: 0;
  -webkit-padding-after: 0;
  -webkit-padding-before: 0;
  -webkit-padding-end: 0;

  cursor: pointer;

  transition: all 0.15s linear;

  ${p =>
    p.disabled &&
    `
    cursor: not-allowed;
  `
  }


  svg > path {
    fill: child;
    ${p =>
      p.primaryBtn &&
      `
     fill: #13BDCE;
    `}
  }

  &:hover {
    svg > path {
      fill: ${p => p.theme.colors.main};
    }

    transition: all 0.15s linear;
  }
`;
const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  border-radius: 4px;
  background-color: #f6f7f9;
`;

const LabelMainText = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-family: Manrope, Inter, sans-serif;
  font-weight: 500;
  color: ${p => p.theme.colors.purple};
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }

  @media (max-width: 1200px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

const LabelSecondaryText = styled.span`
  font-size: 12px;
  line-height: 14px;
  font-family: Manrope, Inter, sans-serif;
  font-weight: 500;
  color: ${p => p.theme.colors.lightText};
  text-transform: lowercase;

  @media (max-width: 1200px) {
    font-size: 12px;
    line-height: 14px;
  }
`;
