import { CacheAccount } from "@common/models";
import { type RootState } from "@common/store";
import { BudgetTransport } from "@common/transports";
import { normalizeError, reorder } from "@common/utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { DropResult } from "react-beautiful-dnd";
import { API, API_V2, setLayoutAccountsList } from "..";

export const LayoutService = {
  loadAccounts: createAsyncThunk<unknown, void>("LayoutLoadAccounts", async (_, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await API.get<CacheAccount[]>(`/accounts/`);
      dispatch(setLayoutAccountsList(data));
      return data
    } catch (err) {
      const { message } = normalizeError(err);
      return rejectWithValue(message);
    }
  }),

  sortOrderCacheAccounts: createAsyncThunk<
    void,
    {
      result: DropResult
    },
    { state: RootState }
  >("LayoutSortOrderCacheAccounts", async ({ result }, { dispatch, rejectWithValue, getState }) => {
    if (!result.destination) {
      return;
    }

    const accounts = getState().layout.accountsList;
    const currentBudget = getState().budgets.currentBudget;
    const accountId = result.draggableId;
    const destination = result.destination.index;
    const source = result.source.index;

    const budgetAccounts = accounts.filter(account => account.budget === currentBudget?.id);

    try {
      const sortedAccounts = reorder(budgetAccounts, source, destination);
      const afterAccountId = sortedAccounts?.[result.destination.index - 1]?.id;

      dispatch(setLayoutAccountsList(sortedAccounts));

      await API_V2.post<void>("/sort-order/cache_account", {
        cache_account_id: accountId,
        after_id: afterAccountId !== undefined ? afterAccountId: undefined,
      })
        .then(() => dispatch(LayoutService.loadAccounts()));

    } catch (err) {
      const { message } = normalizeError(err);
      return rejectWithValue(message);
    }
  }),

  createBudget: createAsyncThunk<
    void,
    {
      name: string
    },
    { state: RootState }
  >("LayoutCreateBudget", async ({ name }, { dispatch, rejectWithValue, getState }) => {
    try {
      await BudgetTransport.createBudget(name);
    } catch (err) {
      const { message } = normalizeError(err);
      return rejectWithValue(message);
    }
  }),
};
