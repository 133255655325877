import { ComponentType, ReactNode } from "react";
import { FieldInputProps, FieldMetaState } from "react-final-form";
import { FormatOptionLabelMeta } from "react-select/dist/declarations/src/Select";
import { DropdownIndicatorProps } from "react-select/dist/declarations/src/components/indicators";
import {
  OptionLabel,
  OptionPrice,
  OptionWrapper,
  SelectContainer,
  SelectLabel,
  StyledChevronDownIcon,
  StyledCreatableSelect,
} from "./styles";
import { SelectOptionWithAmount } from "./types";

export const createReactSelectPrefixClassName = "creatable_select";
export const createReactSelectWrapperClassName = "wrapper";

export interface GroupBase<Option> {
  readonly options: readonly Option[];
  readonly label?: string;
}

type Props<Option, Group extends GroupBase<Option>> = {
  input: FieldInputProps<Option>;
  options: readonly (Option | Group)[];
  placeholder?: string;
  label?: string;
  optionRender: (data: Option, formatOptionLabelMeta: FormatOptionLabelMeta<Option>) => ReactNode;
  groupLabelRender?: (group: GroupBase<Option>) => ReactNode;
  formatCreateLabel?: (inputValue: string) => ReactNode;
  meta?: FieldMetaState<Option>;
  handleCreate?: (inputValue: string) => void
  required?: boolean;
};

export function CreateReactSelect<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: Props<Option, Group>,
) {
  const {
    input,
    optionRender,
    placeholder,
    options,
    groupLabelRender,
    formatCreateLabel,
    label,
    meta,
    required,
    handleCreate,
  } = props;

  return (
    <SelectContainer>
      {label !== undefined && <SelectLabel $required={required}>{label}</SelectLabel>}
      <StyledCreatableSelect<string | ComponentType<any>, string | ComponentType<any>>
        id={input.name}
        value={input.value}
        name={input.name}
        onChange={input.onChange}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        placeholder={placeholder}
        formatOptionLabel={optionRender}
        onCreateOption={handleCreate}
        formatGroupLabel={groupLabelRender}
        options={options}
        className={createReactSelectWrapperClassName}
        formatCreateLabel={input => formatCreateLabel?.(input) || <>{`Создать: "${input}"`}</>}
        classNamePrefix={createReactSelectPrefixClassName}
        components={{
          DropdownIndicator: DropdownIndicator,
        }}
        $error={(meta?.invalid && meta?.touched) || (meta?.invalid && meta?.submitting)}
      />
    </SelectContainer>
  );
}

const DropdownIndicator = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  _: DropdownIndicatorProps<Option, IsMulti, Group>,
) => <StyledChevronDownIcon />;

export function formatOptionWithAmount<Option extends SelectOptionWithAmount>(
  data: Option,
  formatOptionLabelMeta: FormatOptionLabelMeta<Option>,
) {
  const { amount, label } = data;
  const { context } = formatOptionLabelMeta;

  if (context === "value") {
    return <OptionLabel>{label}</OptionLabel>;
  }

  return (
    <OptionWrapper>
      <OptionLabel>{label}</OptionLabel>
      {amount !== undefined && <OptionPrice value={Number(amount)}>{amount} ₽</OptionPrice>}
    </OptionWrapper>
  );
}
