import { API, API_V2 } from "@common/api";

export type SubcategoryTransportType = {
  moveTo: (subcategoryId: string, categoryId: string | undefined, afterId: string | undefined) => Promise<void>
  sortOrder: (subcategoryId: string, afterId: string | undefined) => Promise<void>
}

export const SubcategoryTransport: SubcategoryTransportType = {
  moveTo: async (subcategoryId: string, categoryId: string | undefined, afterId: string | undefined): Promise<void> => {
    return await API.post("/subcategories/move-to", {
      subcategory_id: subcategoryId,
      category_id: categoryId,
      after_id: afterId,
    })
  },
  sortOrder: async (subcategoryId: string, afterId: string | undefined): Promise<void> => {
    return await API_V2.post("/sort-order/subcategory", {
      subcategory_id: subcategoryId,
      after_id: afterId,
    })
  },
}
